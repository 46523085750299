export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        id: 'desktop-index',
        name: 'Escritorio',
        to: '/home',
        icon: 'cil-speedometer',
      },
      {
        _name: 'CSidebarNavTitle',
        id: 'mainmnu-index',
        _children: ['Menu Principal']
      },
      {
        _name: 'CSidebarNavDropdown',
        id:"module-permission",
        name: 'Accesos',
        route: '/base',
        icon: 'cil-people',
        items: [
          {
            style: "display:none",
            id:"permission-index",
            name: 'Permisos',
            to: '/accesos/permisos'
          },
          {
            style: "display:none",
            id:"role-index",
            name: 'Tipo Usuario',
            to: '/accesos/tipos'
          },
          {
            style: "display:none",
            id: "user-index",
            name: 'Usuarios',
            to: '/accesos/usuarios'
          },  
          {
            style: "display:none",
            id: "permission-index",
            name: 'Permisos Asignados',
            to: '/accesos/permisos_asignados'
          }
        ]
      },
      /** mantenimientos */
      {
        _name: 'CSidebarNavDropdown',
        id:"maintenance-module",
        name: 'Mantenimientos',
        route: '/maintenance',
        icon: 'cil-cog',
        items: [
          {
            style: "display:none",
            id:"client-index",
            name: 'Clientes',
            to: '/maintenance/client'
          },
          {
            style: "display:none",
            id:"product-index",
            name: 'Productos',
            to: '/maintenance/products'
          },
        ]
      },
      /** procesos */
      {
        _name: 'CSidebarNavDropdown',
        id:"proccess-module",
        name: 'Procesos',
        route: '/proccess',
        icon: 'cil-cog',
        items: [
          {
            style: "display:none",
            id:"sale-index",
            name: 'Ventas',
            to: '/proccess/sale'
          },
          {
            style: "display:none",
            id:"charge-index",
            name: 'Cobros',
            to: '/proccess/charge'
          },
          // {
          //   style: "display:none",
          //   id:"sale-client",
          //   name: 'Clientes',
          //   to: '/proccess/client'
          // },
        ]
      },
      /** reportes */
      {
        _name: 'CSidebarNavDropdown',
        id:"report-index",
        name: 'Reportes',
        route: '/report',
        icon: 'cil-cog',
        items: [
          {
            style: "display:none",
            id:"report-sales",
            name: 'Reporte ventas',
            to: '/report/sale'
          }
        ]
      },
    ]
  }
]